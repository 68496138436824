import { Locale as DateFnsLocale } from "date-fns";
import { Locale, Locales } from "./types";

export const LOCALES = {
  en_US: "en-US",
  en_GB: "en-GB",
  de_DE: "de-DE",
} as const;

export const AVAILABLE_LOCALES: Locales = Object.values(LOCALES);

export const DEFAULT_LOCALE: Locale = LOCALES.en_GB;

export const DEFAULT_LOCALES: Locales = [DEFAULT_LOCALE];

export const SAVED_LANG_CODE_LOCAL_STORAGE_KEY = "langCode";

export const COMPILED_LOCALES_PATH = "./compiled-lang/";

export const DATE_LOCALE_LOADERS: Record<
  Locale,
  () => Promise<{ default: DateFnsLocale }>
> = {
  [LOCALES.en_GB]: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "df-en-GB" */
      "date-fns/locale/en-GB"
    ),
  [LOCALES.en_US]: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "df-en-US" */
      "date-fns/locale/en-US"
    ),
  [LOCALES.de_DE]: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "df-de-DE" */
      "date-fns/locale/de"
    ),
} as const;
